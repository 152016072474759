<template>
    <div class="full page-layout">
        <slot name="slot1" />
    </div>
</template>

<script>
export default {
    name: 'FullLayout'
};
</script>

<style language="scss" scoped>
.full {
    width: 100%;
}
</style>